const isWindowSizeSp = window.matchMedia('(max-width: 1023px)').matches
const headerNavLinkLoginDOM = document.querySelector<HTMLElement>(
  '#js-header-navLinkLogin'
)
const headerNavLinkAccordionDOMArr = document.querySelectorAll('.js-header-navLinkAccordion')

const clickHamburgerBtn = () => {
  const bodyDOM = document.querySelector<HTMLElement>('body')
  const headerHumbargerOpenBtnDOM = document.querySelector<HTMLElement>(
    '#js-header-humbargerOpenBtn'
  )
  const headerNavListLeftDOM = document.querySelector<HTMLElement>(
    '#js-header-navListLeft'
  )
  headerHumbargerOpenBtnDOM?.addEventListener('click', function() {
    this.classList.toggle('clicked')
    headerNavListLeftDOM?.classList.toggle('active')
    bodyDOM?.classList.toggle('h-screen')
    bodyDOM?.classList.toggle('overflow-y-hidden')
    headerNavLinkLoginDOM?.classList.remove('active')
  })
}

const loginHover = () => {
  if(isWindowSizeSp) {
    headerNavLinkLoginDOM?.addEventListener('click', function() {
      this.classList.toggle('active')
    })
  } else {
    headerNavLinkLoginDOM?.addEventListener('mouseover', function() {
      this.classList.add('active')
    })
    headerNavLinkLoginDOM?.addEventListener('mouseleave', function() {
      this.classList.remove('active')
    })
  }
}

const seminarItemEvent = () => {
  headerNavLinkAccordionDOMArr.forEach(el => {
    if(isWindowSizeSp) {
      el?.addEventListener('click', () => {
        el.classList.toggle('active')
    })
    } else {
      el?.addEventListener('mouseover', () => {
        el.classList.add('active')
      })
      el?.addEventListener('mouseleave', () => {
        el.classList.remove('active')
      })
    }
  })
}

export const header = () => {
  clickHamburgerBtn()
  loginHover()
  seminarItemEvent()
}
