import "./scss/common.scss";
import { header } from './ts/common/header';
import { leadBrn } from './ts/common/leadBnr';

// windowに何かつっこんだ場合の型宣言です
declare global {
  interface Window {
    get_theme_file_url: String;
  }
}

header()
leadBrn()

const app = document.querySelector<HTMLDivElement>("#app")!;

if (app) {
  app.innerHTML = `<h1>Hello Vite!</h1>`;
}
