const leadBnrModal = document.querySelector<HTMLElement>('#js-leadBnr-modal')

const leadBnrDisplay = () => {
  window.onload = function() {
    setTimeout(() => {
      leadBnrModal?.classList.remove('opacity-0')
      leadBnrModal?.classList.remove('translate-x-[100vw]')
    }, 3000);
  }
}

const leadBrnClose = () => {
  const leadBnrCloseBtnDOM = document.querySelector<HTMLElement>('#js-leadBnr-closeBtn')
  leadBnrCloseBtnDOM?.addEventListener('click', () => {
    leadBnrModal?.classList.add('hidden')
  })
}

export const leadBrn = () => {
  leadBnrDisplay()
  leadBrnClose()
}